body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}




body, #main
{	
	background: #0a0e10;
	min-width: 100vw;
	min-height: 100vh;
	overflow-x: hidden;
	margin:0;
	padding:0;
	
	
}


@font-face {
  font-family: 'ux-modern-normal';
  src: local('ux-modern-normal'), url(/static/media/SF-Pro-Rounded-Light.7f224b09.otf) format('opentype');
}

@font-face
{
  font-family: 'ux-modern-bold';
  src: local('ux-mondern-bold'), url(/static/media/SF-Pro-Rounded-Bold.c92cf020.otf) format('opentype');
}




.card_group
{
	display:flex;
	justify-content: center;
	min-height: 100vh;
	
}

.defualt_card_header img
{
	width: 50%;
	margin: auto;
	display:block;
	
}


.default_logo img
{
	width: 70px;
	margin-left: 10%;
	margin-top: 2%;
	
}

.wave_foot_default
{
	width: 100%;
	
}

.default_card
{

	min-height: -webkit-min-content;

	min-height: -moz-min-content;

	min-height: min-content;
	min-width: -webkit-min-content;
	min-width: -moz-min-content;
	min-width: min-content;
	
	max-width: 300px;
	max-height: 400px;
	box-shadow: 0px 0px 75px -48px rgba(0,221,255,1);
	background: black;
	margin:50px;
	color:white;
	border-radius: 10px;
	background: #0f1317;
 animation:  rainbow-shadow 10.0s linear infinite;
	
	
}

@keyframes rainbow-shadow
{
	100%, 0% {
		box-shadow: 0px 0px 75px -48px  rgb(255,0,0);
		-moz-box-shadow: 0px 0px 75px -48px  rgb(255,0,0);
		-webkit-box-shadow: 0px 0px 75px -48px  rgb(255,0,0);
		
	}
	8% {
		box-shadow: 0px 0px 75px -48px  rgb(255,127,0);
		-moz-box-shadow: 0px 0px 75px -48px  rgb(255,127,0);
		-webkit-box-shadow: 0px 0px 75px -48px  rgb(255,127,0);
	}
	16% {
		box-shadow: 0px 0px 75px -48px  rgb(255,255,0);
		-moz-box-shadow: 0px 0px 75px -48px  rgb(255,255,0);
		-webkit-box-shadow: 0px 0px 75px -48px  rgb(255,255,0);
	}
	25% {
		box-shadow: 0px 0px 75px -48px  rgb(127,255,0);
		-moz-box-shadow: 0px 0px 75px -48px  rgb(127,255,0);
		-webkit-box-shadow: 0px 0px 75px -48px  rgb(127,255,0);
	}
	33% {
		box-shadow: 0px 0px 75px -48px  rgb(0,255,0);
		-moz-box-shadow: 0px 0px 75px -48px  rgb(0,255,0);
		-webkit-box-shadow: 0px 0px 75px -48px  rgb(0,255,0);
	}
	41% {
		box-shadow: 0px 0px 75px -48px  rgb(0,255,127);
		-webkit-box-shadow: 0px 0px 75px -48px  rgb(0,255,127);
		-moz-box-shadow: 0px 0px 75px -48px  rgb(0,255,127);
	}
	50% {
		box-shadow: 0px 0px 75px -48px  rgb(0,255,255);
		-webkit-box-shadow: 0px 0px 75px -48px  rgb(0,255,255);
		-moz-box-shadow: 0px 0px 75px -48px  rgb(0,255,255);
	}
	58% {
		box-shadow: 0px 0px 75px -48px  rgb(0,127,255);
		-moz-box-shadow: 0px 0px 75px -48px  rgb(0,127,255);
	}
	66% {
		box-shadow: 0px 0px 75px -48px  rgb(0,0,255);
		-webkit-box-shadow: 0px 0px 75px -48px  rgb(0,0,255);
	}
	75% {
		box-shadow: 0px 0px 75px -48px  rgb(127,0,255);
		-webkit-box-shadow: 0px 0px 75px -48px  rgb(127,0,255);
		-moz-box-shadow: 0px 0px 75px -48px  rgb(127,0,255);
	}
	83% {
		box-shadow: 0px 0px 75px -48px  rgb(255,0,255);
		-webkit-box-shadow: 0px 0px 75px -48px  rgb(255,0,255);
		-moz-box-shadow: 0px 0px 75px -48px  rgb(255,0,255);
	}
	91% {
		box-shadow: 0px 0px 75px -48px  rgb(255,0,127);
		-webkit-box-shadow: 0px 0px 75px -48px  rgb(255,0,127);
		-moz-box-shadow: 0px 0px 75px -48px  rgb(255,0,127);
	}
}


.defualt_card_header
{
	font-family: ux-modern-bold;
	margin: 20px;
	text-align: center;
}

.default_card_paragraph 
{
	margin: 20px;
	font-family: ux-modern-normal;
}




